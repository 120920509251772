<template>
  <a-row :gutter="10" class="full-card">
    <a-col span="6" style="height: 100%">
      <a-card title="项目职位" :bodyStyle="{'height' : 'calc(100% - 60px)'}">
        <div slot="extra">
          <a-button size="small" type="primary" ghost @click="postSubmit" v-has-permission="['setProjectPostion']">保存</a-button>
        </div>
        <div class="height100">
          <div class="overflow-y-a" style="height: calc(100% - 130px)">
            <a-row type="flex" justify="space-between" align="middle" class="margin-b20" v-for="(item, index) in postList" :key="item.id">
            <a-col span="18">
              <a-input v-model="item.positionName" :disabled="!authority.setProjectPostion"/>
            </a-col>
            <a-col span="2">
              <img src="@/assets/image/common/del.png" alt="" @click="delPost(index)" v-has-permission="['setProjectPostion']">
            </a-col>
          </a-row>
          </div>
          <div v-has-permission="['setProjectPostion']">
            <div class="display-f align-items-c margin-t40">
              <span>新增项目职位：</span>
              <a-input style="flex: 1; margin-left: 20px" v-model="post"/>
            </div>
            <div class="margin-t20">
              <a-button type="primary" block @click="addPost">确认添加</a-button>
            </div>
          </div>
        </div>
      </a-card>
    </a-col>
    <a-col span="6" style="height: 100%">
      <a-card title="任务类型" :bodyStyle="{'height' : 'calc(100% - 60px)'}">
        <div slot="extra">
          <a-button size="small" type="primary" ghost @click="taskTypeSubmit" v-has-permission="['setTaskCategory']">保存</a-button>
        </div>
        <div class="height100">
          <div class="overflow-y-a" style="height: calc(100% - 130px)">
            <a-row type="flex" justify="space-between" align="middle" class="margin-b20" v-for="(item, index) in taskTypeList" :key="item.id">
            <a-col span="18">
              <a-input v-model="item.categoryName" :disabled="!authority.setTaskCategory"/>
            </a-col>
            <a-col span="2">
              <img src="@/assets/image/common/del.png" alt="" @click="delTaskType(index)" v-has-permission="['setTaskCategory']">
            </a-col>
          </a-row>
          </div>
            <div v-has-permission="['setTaskCategory']">
            <div class="display-f align-items-c margin-t40">
              <span>新增任务类型：</span>
              <a-input style="flex: 1; margin-left: 20px" v-model="taskType" />
            </div>
            <div class="margin-t20">
              <a-button type="primary" block @click="addTaskType">确认添加</a-button>
            </div>
          </div>
        </div>
      </a-card>
    </a-col>
    <a-col span="6" style="height: 100%">
      <a-card title="任务职位" :bodyStyle="{'height' : 'calc(100% - 60px)'}">
        <div slot="extra">
          <a-button size="small" type="primary" ghost @click="taskPositionSubmit" v-has-permission="['setTaskPosition']">保存</a-button>
        </div>
        <div class="height100">
          <div class="overflow-y-a" style="height: calc(100% - 130px)">
            <a-row type="flex" justify="space-between" align="middle" class="margin-b20" v-for="(item, index) in taskPositionList" :key="item.id">
              <a-col span="18">
                <a-input v-model="item.positionName" :disabled="!authority.setTaskPosition"/>
              </a-col>
              <a-col span="2">
                <img src="@/assets/image/common/del.png" alt="" @click="delTaskPosition(index)" v-has-permission="['setTaskPosition']">
              </a-col>
            </a-row>
          </div>
          <div v-has-permission="['setTaskPosition']">
            <div class="display-f align-items-c margin-t40">
              <span>新增任务职位：</span>
              <a-input style="flex: 1; margin-left: 20px" v-model="taskPosition" />
            </div>
            <div class="margin-t20">
              <a-button type="primary" block @click="addTaskPosition">确认添加</a-button>
            </div>
          </div>
        </div>
      </a-card>
    </a-col>
    <a-col span="6" style="height: 100%">
      <a-card title="预算/实际支出类目" :bodyStyle="{'height' : 'calc(100% - 60px)'}">
        <div slot="extra">
          <a-button size="small" type="primary" ghost @click="categorySubmit" v-has-permission="['setExpenditureCategory']">保存</a-button>
        </div>
        <div class="height100">
          <div class="overflow-y-a" style="height: calc(100% - 130px)">
            <a-row type="flex" justify="space-between" align="middle" class="margin-b20" v-for="(item, index) in categoryList" :key="item.id">
              <a-col span="18">
                <span v-if="item.categoryName === '设备采购费'">{{ item.categoryName }}</span>
                <a-input v-model="item.categoryName" :disabled="!authority.setExpenditureCategory" v-else/>
              </a-col>
              <a-col span="2">
                <img src="@/assets/image/common/del.png" alt="" @click="delCategory(index)" v-has-permission="['setExpenditureCategory']" v-show="item.categoryName !== '设备采购费'">
              </a-col>
            </a-row>
          </div>
          <div v-has-permission="['setExpenditureCategory']">
            <div class="display-f align-items-c margin-t40">
              <span>新增预算/实际支出类目：</span>
              <a-input style="flex: 1; margin-left: 20px" v-model="category" />
            </div>
            <div class="margin-t20">
              <a-button type="primary" block @click="addCategory">确认添加</a-button>
            </div>
          </div>
        </div>
      </a-card>
    </a-col>
  </a-row>
</template>

<script>
import {
  getPositionList,
  getTaskPositionList,
  getTaskTypeList,
  savePosition,
  saveTaskType,
  saveTaskPosition, saveCategory, getCategoryList
} from '../../api/system/settings'
import { mapState } from 'vuex'

export default {
  name: 'settings',
  data () {
    return {
      post: '',
      postList: [],
      taskType: '',
      taskTypeList: [],
      taskPosition: '',
      taskPositionList: [],
      category: '',
      categoryList: []
    }
  },
  mounted () {
    this.getPostList()
    this.getTaskTypeList()
    this.getTaskPositionList()
    this.getCategoryList()
  },
  methods: {
    getPostList () {
      getPositionList().then(res => {
        this.postList = res.data.list
        console.log(this.postList)
      })
    },
    addPost () {
      this.postList.push({positionName: this.post})
      this.post = ''
    },
    delPost (index) {
      this.postList.splice(index, 1)
    },
    postSubmit () {
      savePosition({
        projectPositionList: this.postList
      }).then(() => {
        this.$message.success('保存成功！')
      })
    },
    getTaskTypeList () {
      getTaskTypeList().then(res => {
        this.taskTypeList = res.data.list
      })
    },
    addTaskType () {
      this.taskTypeList.push({categoryName: this.taskType})
      this.taskType = ''
    },
    delTaskType (index) {
      this.taskTypeList.splice(index, 1)
    },
    taskTypeSubmit () {
      saveTaskType({
        taskCategoryList: this.taskTypeList
      }).then(() => {
        this.$message.success('保存成功！')
      })
    },
    getTaskPositionList () {
      getTaskPositionList().then(res => {
        this.taskPositionList = res.data.list
      })
    },
    addTaskPosition () {
      this.taskPositionList.push({positionName: this.taskPosition})
      this.taskPosition = ''
    },
    delTaskPosition (index) {
      this.taskPositionList.splice(index, 1)
    },
    taskPositionSubmit () {
      saveTaskPosition({
        taskPositionList: this.taskPositionList
      }).then(() => {
        this.$message.success('保存成功！')
      })
    },
    getCategoryList () {
      getCategoryList().then(res => {
        this.categoryList = res.data.list
      })
    },
    addCategory () {
      this.categoryList.push({categoryName: this.category})
      this.category = ''
    },
    delCategory (index) {
      this.categoryList.splice(index, 1)
    },
    categorySubmit () {
      saveCategory({
        expenditureCategoryList: this.categoryList
      }).then(() => {
        this.$message.success('保存成功！')
      })
    },
  },
  computed: {
    ...mapState({
      // 动态主路由
      authority: state => state.user.authority,
    }),
  },
}
</script>

<style scoped lang="scss">
  img {
  cursor:pointer;
  }
</style>
